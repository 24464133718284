<template>
  <div class="main">
    <p>监控</p>
    <div class="contentarea hidden">
      <div class="play-error-page container">
        <div class="middle-box">
          <div class="cd-feedback cd-feedback-danger">
            <div class="feedback-icon">
              <i class="cd-icon cd-icon-danger"></i>
            </div>
            <div class="feedback-title">摄像头未启用或不存在</div>
            <div class="feedback-content">
              <p>请检查电脑是否安装摄像头并启用</p>
            </div>
          </div>
        </div>
      </div>
      <div class="camera">
        <video id="video">Video stream not available.</video>
        <button id="startbutton">Take photo</button>
      </div>
      <canvas id="canvas"> </canvas>
      <div class="output">
        <img id="photo" class="capture-photo" alt="" />
      </div>
    </div>
    <div
      id="saving-process-dialog"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">正在保存进度……</h4>
          </div>
          <div class="modal-body">
            <p>正在保存答题进度，请稍等</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" data-dismiss="modal">
              取消
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <div
      id="not-self-alert-dialog"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title color-warning">面部识别与本人不符</h4>
          </div>
          <div class="modal-body">
            <div
              id="not-self-alert-text"
              class="alert alert-warning"
              role="alert"
            ></div>
            <div><img id="not-self-img" class="capture-photo" /></div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary btn-retake">
              重新拍照
            </button>
            <button type="button" class="btn btn-info btn-submit ml10">
              提交识别
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.contentarea {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.contentarea > * {
  display: none;
}

.contentarea .play-error-page {
  display: table;
  height: 100%;
}

.contentarea .play-error-page .middle-box {
  display: table-cell;
  vertical-align: middle;
}
.modal img {
  max-width: 100%;
}
</style>